@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-bg {
  background-image: url("background.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.card {
  background-color: #c1c4c7;
  width: 75vw;
  height: 75vh;
  border-radius: 14px;
  position: relative;
}

.card-top {
  background-image: url("background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.card-bottom {
  background-color: #c1c4c7;
  display: flex;
  /* justify-content: center; */
  padding: 10px;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.card-input {
  background-color: #ffffff; /* Or any color you prefer */
}

.card-button {
  background-color: #67719e; /* Or any color you prefer */
  display: flex;
  color: #ffffff; /* Corrected color specification */
  justify-content: center;
  padding: 10px; /* Adjust padding as needed */
  font-size: 0.875rem;
  width: 100%;
}

.scrollable-content {
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: 100%; /* Adjust this as needed */
}

.bottom-border-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.top-border-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
